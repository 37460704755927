import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function Message({ message }) {
  var hl=message.content.match(/[א-ת]/g)?.length || 0
  var el=message.content.match(/[a-z]/g)?.length || 0
  var isHebrew=hl> el
  return <div style={{
    textAlign:message.role=="user" ? 'left' : null,
    width:'100%',
  }}>
    <div style={{display:'flex'}}>
      {message.role!='user' && <div
      style={{
        width:40,
        height:40,
        marginLeft:10,
        marginTop:20,
        backgroundImage:'url(/logo.png)',
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
      }}
      ></div>}
      <div style={{ 
      textAlign:isHebrew ? 'right' : 'left',
      padding: 10,
      textAlign: isHebrew ? 'right' :'left', 
      direction: isHebrew ? 'rtl' : 'ltr',
      marginRight:'auto',
      lineHeight:1.5,
      fontSize:18,
      display:'inline-block',
      borderRadius:message.role=='user' ? 30:null,
      padding:message.role=='user' ? 10:null,
      marginBottom:20,
      maxWidth:message.role=='user' ? '80%':'100%',
      width:message.role =='user'?null:'100%', 
      backgroundColor:message.role=='user' ? '#F4F4F4':null
      }}>
    <Markdown
    remarkPlugins={[remarkGfm]}
    >{message.content}</Markdown>
    </div>
  </div>
  </div>
}