import { env } from "./env"

export default async function requestJSON(url,body){
  var res =await fetch(env.backend + url,{
    method:'POST',
    headers:{
      'Content-Type':'application/json'
    },
    body:JSON.stringify({
      ...body,
      auth:localStorage.auth
  })
  })
  return res.json()
}