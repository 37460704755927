import { env } from "./env";

//@ts-check
export default async function sendPrompt(prompts,onText,onEnd){
  var res=await fetch(env.backend + '/completions',{
    method:'POST',
    headers:{
      'Content-Type':'application/json'
    },
    body:JSON.stringify({
      auth:localStorage.auth,
      ...prompts
  })
  })
  var text=''
  //@ts-ignore
  const reader = res.body.getReader();
  var done=false
  while(!done) {
    var {done,value}=await reader.read()
    if(done) break
    var a=(new TextDecoder('utf-8')).decode(value)
    text+=a
    onText(text)
  }
  onEnd()
}