//@ts-check
import './App.css';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import React, { useEffect, useRef, useState } from 'react';
import requestJSON from './requestJSON';
import useAsyncEffect from './use-async-effect';
import sendPrompt from './sendPrompt';
import Message from './message';
import useStyleSheet from './useStyleSheet';
import { env } from './env';

function App() {
  var auth = new URLSearchParams(window.location.search).get('auth')
  var inputRef=useRef()
  var messagesRef=useRef()
  var [loggedIn, set_loggedIn] = useState(null)
  var [user, set_user] = useState()
  var [messages,set_messages]=useState([{
    role:'assistant',
    content:`שלום! תרשום לי משפט או פיסקה באנגלית ואני ארשום לך האם צריך לתקן אותו.
אני גם ארשום לך בדיוק את התיקונים, אסביר על כל תיקון, ולבסוף אשתף אותך האם מה שכתבת מובן, והאם יש לי על זה שאלות.

שימושי לדוגמא עבור מיילים שצריך לשלוח בעבודה.`
  }])
  var [typing,set_typing]=useState(false)

  if (auth) {
    localStorage.auth = auth
    window.open('/', '_top')
  }
  var [showHover,set_showHover]=useState(false)
  useStyleSheet(`
    code {
    background-color:#0D0D0D;
    direction:ltr;
    color: white;
    display: block;
    text-align: left;
    font-size:12px;
    padding:16px;
    overflow:scroll;
    }
    `)
  useAsyncEffect(async () => {
    if (localStorage.auth) {
      try {
        var user = await requestJSON('/userFromAuth', { auth: localStorage.auth })
        } catch(err) {
        console.log(err)
        return
      }
      set_user(user)
      //@ts-ignore
      set_loggedIn(true)
    } else {
      //@ts-ignore
      set_loggedIn(false)
    }
  }, [])
  if (loggedIn === false) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}>
      <div>
      <a href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${env.google_client_id}&redirect_uri=${env.backend}/google_callback&response_type=code&scope=email%20profile&access_type=online`}>
        <img src="/google-button.png" height="50"/>
        <br/>
        <br/>
      </a>
        <div style={{color:'gray'}}>
        בעת ההתחברות אתה מאשר את תנאי השימוש
        <br/>
        וקבלת מיילים שיווקים בנוגע לשירות.
        </div>
      </div>
      </div>
    )
  }
  function logout(){
    set_loggedIn(false)
    delete localStorage.auth
  }
  function clickUser(){
    function hd(){
      set_showHover(false)
      document.removeEventListener('click',hd)
    }
    if(!showHover) {
      setTimeout(()=>{
        document.addEventListener('click',hd)
      },500)
    }
    set_showHover(!showHover)
  }
  function oninput(){
    var isHebrew = inputRef.current.value.match(/[א-ת]/)
    inputRef.current.style.direction=!isHebrew ? 'ltr' : 'rtl'
    inputRef.current.style.height = "5px";
    inputRef.current.style.height = (inputRef.current.scrollHeight) + "px";
  }
  function submit(message) {
    inputRef.current.value=''
    messages.push({
      role:'user',
      content:message
    })
    messages.push({
      role:'assistant',
      content:''
    })
    var currentIndex=messages.length-1
    set_typing(true)
    sendPrompt({
      messages:[
        ...messages.slice(0,-1),
        {
          role:'user',
          content:message
          }
          ]
          },(text)=>{
      messages[currentIndex].content=text
      set_messages([...messages])
      setTimeout(()=>{
      messagesRef.current.scrollTop=messagesRef.current.scrollHeight
      },100)
      console.log(inputRef.current)
      },()=>{
        set_typing(false)
        setTimeout(()=>{
          inputRef.current.focus()
        },100)
    })
  }
  if (loggedIn) {
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div>
        <div style={{ direction: 'ltr', padding: 10,position:'relative' }}>
          <div 
          onClick={clickUser}
          style={{
            width: 50,
            textAlign: 'left',
            height: 50,
            cursor:'pointer',
            backgroundSize: 'cover',
            borderRadius: '50%',
            backgroundImage: 'url(' + "data:image/png;base64," + user.picture + ')'
          }} />
        {showHover &&
        <div style={{position:'absolute',left:0,padding:10}}>          
          <div onClick={logout} style={{cursor:'pointer'}}>
            התנתקות
            </div>
        </div>}
        </div>
      </div>
      <div 
      ref={messagesRef}
      style={{ 
        overflowY:'auto',
        flexGrow: 1,padding:20,maxWidth:800,marginLeft:'auto',marginRight:'auto' }}>
        {messages.map((message,index)=><Message key={index} message={message}/>)}
      </div>
      <div style={{textAlign:'center' }}>
        <textarea
        maxLength={4000}
        ref={inputRef}
        autoFocus
        onInput={oninput}
        disabled={typing}
          placeholder='הקלידו כאן'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              oninput()
              submit(e.target.value)
            }
          }}
          style={{
            boxSizing:'border-box',
            width: '100%',
            maxWidth:800,
            fontSize: 18,
            border: 'none',
            borderRadius: '30px',
            padding: 20,
            background: '#F4F4F4'
          }} />
      </div>
      <div style={{ padding: 10, color: '#969696', textAlign: 'center' }}>
        מערכת ג'יפו יכולה לעשות טעויות. יש לבדוק מידע חשוב
      </div>
    </div>
  }
}

export default App;
